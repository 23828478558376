import React from 'react';

// === Helpers === //
import { Link } from 'gatsby-plugin-react-i18next';

// === Styles === //
import styled from '@emotion/styled';
import { breakpoints, colors, fonts, mixins, respondFrom } from '@styles';
import { css } from '@emotion/react';

const StyledLink = styled(Link)`
  display: inline-block;
  background-color: ${colors.ui.main};
  text-decoration: none;
  color: ${colors.text.default};
  padding: 22px 40px;
  border-radius: 80px;
  font-size: 18px;
  font-family: ${fonts.extraBold};
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  ${mixins.transitionDefault};

  ${respondFrom(
    breakpoints.md,
    css`
      padding: 22px 48px;
    `
  )};

  ${respondFrom(
    breakpoints.lg,
    css`
      padding: 24px 73px 22px 72px;

      &:hover {
        background-color: ${colors.ui.grayDark};
        color: ${colors.white};
      }
    `
  )};

  &.variant {
    &--light {
      background-color: ${colors.white};

      ${respondFrom(
        breakpoints.lg,
        css`
          &:hover {
            background-color: ${colors.ui.grayDark};
            color: ${colors.white};
          }
        `
      )}
    }
  }
`;

const StyledLinkExternal = styled.a`
  display: inline-block;
  background-color: ${colors.ui.main};
  text-decoration: none;
  color: ${colors.text.default};
  padding: 22px 40px;
  border-radius: 80px;
  font-size: 18px;
  font-family: ${fonts.extraBold};
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  ${mixins.transitionDefault};

  ${respondFrom(
    breakpoints.md,
    css`
      padding: 22px 48px;
    `
  )};

  ${respondFrom(
    breakpoints.lg,
    css`
      padding: 24px 73px 22px 72px;

      &:hover {
        background-color: ${colors.ui.grayDark};
        color: ${colors.white};
      }
    `
  )};

  &.variant {
    &--light {
      background-color: ${colors.white};

      ${respondFrom(
        breakpoints.lg,
        css`
          &:hover {
            background-color: ${colors.ui.grayDark};
            color: ${colors.white};
          }
        `
      )}
    }
  }
`;

interface ButtonLinkProps {
  children: React.ReactChild | string;
  to: string;
  external?: boolean;
  variant?: 'default' | 'light';
}

const ButtonLink = ({ children, to, external = false, variant = 'default' }: ButtonLinkProps) => {
  return (
    <div>
      {external ? (
        <StyledLinkExternal href={to} target="_blank" className={`variant--${variant}`}>
          {children}
        </StyledLinkExternal>
      ) : (
        <StyledLink to={to} className={`variant--${variant}`}>
          {children}
        </StyledLink>
      )}
    </div>
  );
};

export default ButtonLink;
